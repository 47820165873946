import React            from 'react'
import Helmet           from 'react-helmet'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import config           from '../../../../data/site-config'

import Layout           from '../../../components/layout'
import MainWrapper      from '../../../components/MainWrapper/MainWrapper'
import PageHero         from '../../../components/PageHero'
import PortfolioPagination  from '../../../components/Portfolio/PortfolioPagination'

const FluidityTechPage = () => (
  <Layout>
    <Helmet
      title={`🕹 Fluidity Tech • Drone Startup Revolutionizing Flight Control`}
      meta={[
        { name: 'description', content: 'Fluidity Tech' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/portfolio/fluidity-tech/` }
      ]}
      bodyAttributes={{
        class: 'light-theme'
      }}
    />

    <PageHero
      bgColor="#0761C1"
      bgImage="/portfolio/fluidity-tech/fluidity-bg.jpg">
        <img src="/client-logos/fluidity-tech-icon.svg" className="" alt="Fluidity Tech logo" />
        <h1 className="t-headline">Fluidity Tech&mdash;<span className="o-40">a drone startup revolutionizing flight control</span></h1>
    </PageHero>

    <MainWrapper>

      <section className="pv5 pv6-ns bg-near-white tc">
        <span className="t-small-caps red">TL;DR</span>
        <h2 className="t-mega measure-tight center">Kickstarting a unique product with a drone startup</h2>
      </section>

      <div className="pv5 pv6-ns row narrow">
        <h3 className="f2">Lift Off</h3>
        <p>Fluidity Tech set out to bring a new product to market.</p>
        <p>The FT Aviator is the first of its kind drone controller that puts full flight control in a single hand. It provides a more intuitive response for people to fly drones&mdash;compared to using the classic two thumbs controller.</p>
      </div>

      <div className="row narrow">

        <h3 className="f2">Brand Guidelines</h3>
        <p>When we began working with Fluidity, the name and logo already existed. From that point, we continued to develop a visual direction for the brand as a whole and applicable mediums.</p>
        <p>By roughly mocking up a variety of concepts we settled on a course for future design and implementation of social media design, ads, web, and print.</p>

      </div>

      <img src="/portfolio/fluidity-tech/fluidity-tech-brand-guidelines-cover.jpg" className="center db" alt="Fluidity Tech brand guidelines booklet cover" />

      <div className="bg-near-white pv5">
        <img src="/portfolio/fluidity-tech/fluidity-tech-brand-guidelines.jpg" className="db center w-90 mw8" alt="Fluidity Tech brand guidelines page designs" />
      </div>

      <div className="row narrow pt6">

        <h3 className="f2">Kickstarter</h3>
        <p>The first public reveal of the FT Aviator was on Kickstarter, starting November 1, 2018. The campaign ran for 30 days with the goal to raise $50,000.</p>
        <p>The campaign&mdash;coupled with a strong press push and marketing&mdash;was a huge success ending 200+% funded.</p>

      </div>

      <div className="bg-banded flex items-start justify-center mv5 mv6-l">
        <img src="/portfolio/fluidity-tech/fluidity-tech-drone-kickstarter-design.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech on Kickstarter" />
        <img src="/portfolio/fluidity-tech/fluidity-tech-kickstarter-graphic.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech Kickstarter page" />
      </div>

      <div className="row narrow">

        <h3 className="f2">On the web</h3>
        <p>The Fluidity Tech online home is a custom built WordPress website, combined with a Shopify eCommerce store.</p>

      </div>

      <div className="bg-banded flex items-start justify-center mv5 mv6-l">
        <img src="/portfolio/fluidity-tech/fluidity-tech-pre-launch-website-design.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech pre launch website design" />
        <img src="/portfolio/fluidity-tech/fluidity-tech-launch-website-design.jpg" className="w-40 w-30-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech launch website designs" />
      </div>

      <div className="flex items-start justify-center mv5 mv6-l">
        <img src="/portfolio/fluidity-tech/fluidity-tech-web-design.jpg" className="w-40 w-25-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech drones for hobbyist" />
        <img src="/portfolio/fluidity-tech/fluidity-tech-web-ui-design.jpg" className="w-40 w-25-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech drones for public safety" />
        <img src="/portfolio/fluidity-tech/fluidity-tech-web-ux.jpg" className="w-40 w-25-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech drones for cinematography" />
        <img src="/portfolio/fluidity-tech/fluidity-tech-wordpress-design.jpg" className="w-40 w-25-l mh2 mh4-l br2 box-shadow relative" alt="Fluidity Tech drones for inspections" />
      </div>

      <OutboundLink
        href="https://fluidity.tech/"
        target="_blank"
        className="big-circle-button hover-bg-blue center mb7">
        Visit Fluidity Tech ↗
      </OutboundLink>

      <PortfolioPagination
        link={`dude-perfect`}
        name={`Dude Perfect`}
        tagline={`YouTube influencers specializing in the trick shot`}
        color={`#00ffde`}
      />

    </MainWrapper>

  </Layout>
)

export default FluidityTechPage
